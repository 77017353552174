<template>
  <div>
    <v-dialog v-model="dialog" max-width="1300" non-persistent>
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} course`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="hideModal">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
      <div class="row">
        <v-col cols="12" md="6">
          <v-text-field
              label="Course title"
              outlined
              dense
              v-model="course.title"
          >

          </v-text-field>
          <span class="text-danger" v-if="$v.course.title.$error">This information is required</span>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
              label="Course subject code"
              outlined
              dense
              v-model="course.subject_code"
          >

          </v-text-field>

        </v-col>
        <v-col cols="3" md="3">
          <v-select
              :items="programs"
              label="Program"
              item-text="title"
              item-value="id"
              outlined
              dense
              v-model="course.program_id"
              @change="getGradesByProgramId(course.program_id)"
          >

          </v-select>
          <span class="text-danger" v-if="$v.course.grade_id.$error">This information is required</span>
        </v-col>
        <v-col cols="3" md="3">
          <v-select
              :items="gradesLevels"
              label="Semester/year"
              item-text="title"
              item-value="id"
              outlined
              dense
              v-model="course.grade_id"
          >

          </v-select>
          <span class="text-danger" v-if="$v.course.grade_id.$error">This information is required</span>
        </v-col>
        <v-col cols="3" md="3">
          <v-text-field
              label="Duration"
              outlined
              dense
              v-model="course.duration"
          >

          </v-text-field>
          <span class="text-danger" v-if="$v.course.duration.$error">This information is required</span>
        </v-col>

        <v-col cols="3" md="3">
          <v-select
              :items="['Hours','Days' ,'Months','Years']"
              label="Duration unit"
              outlined
              dense
              v-model="course.duration_unit"
          >

          </v-select>
          <span class="text-danger" v-if="$v.course.duration_unit.$error">This information is required</span>
        </v-col>

        <v-col cols="3" md="3">
          <v-select
              :items="['Grade','Percentage']"
              label="Mark Type"
              outlined
              dense
              v-model="course.mark_type"
          >

          </v-select>
        </v-col>

        <v-col cols="3" md="3">
          <v-text-field
              label="Theory Mark"
              outlined
              dense
              v-model="course.theory_mark"
          >

          </v-text-field>
          <span class="text-danger" v-if="$v.course.theory_mark.$error">This information is required</span>
        </v-col>

        <v-col cols="3" md="3">
          <v-text-field
              label="Theory pass mark"
              outlined
              dense
              v-model="course.theory_pass_mark"
          >

          </v-text-field>
          <span class="text-danger" v-if="$v.course.theory_pass_mark.$error">This information is required</span>
        </v-col>

        <v-col cols="3" md="3">
          <v-text-field
              label="Theory credit hours"
              outlined
              dense
              v-model="course.theory_credit_hours"
          >

          </v-text-field>
        </v-col>
        <v-col cols="3" md="3">
          <v-text-field
              label="Theory grade point"
              outlined
              dense
              v-model="course.theory_grade_points"
          >

          </v-text-field>
        </v-col>
        <div class="col-3  mt-5">
          <v-switch v-model="course.has_practical" name="check-button" label="Has practical ?">
          </v-switch>
        </div>
        <v-col cols="2" md="2" v-if="course.has_practical">
          <v-text-field
              label="Practical credit hours"
              outlined
              dense
              v-model="course.practical_credit_hours"
          >

          </v-text-field>
        </v-col>
        <v-col cols="2" md="2" v-if="course.has_practical">
          <v-text-field
              label="Practical grade point"
              outlined
              dense
              v-model="course.practical_grade_points"
          >

          </v-text-field>
        </v-col>
        <v-col cols="2" md="2" v-if="course.has_practical">
          <v-text-field
              label="Practical mark"
              outlined
              dense
              v-model="course.practical_mark"
          >

          </v-text-field>
          <span class="text-danger" v-if="$v.course.practical_mark.$error">This information is required</span>
        </v-col>

        <v-col cols="2" md="2" v-if="course.has_practical">
          <v-text-field
              label="Practical pass mark"
              outlined
              dense
              v-model="course.practical_pass_mark"
          >

          </v-text-field>
          <span class="text-danger" v-if="$v.course.practical_pass_mark.$error">This information is required</span>
        </v-col>


        <div class="col-12 form-group">
          <label for class>Content</label>
          <br/>
          <ckeditor :config="editorConfig" v-model="course.content"></ckeditor>


        </div>

        <div class="col-12 form-group">
          <label for class>Cover image</label>
          <br/>
          <v-file-input
              show-size
              counter
              outlined
              dense
              v-model="course.cover_image"
              label="Cover image"
          ></v-file-input>

          <v-img height="300" width="300" v-if="course.cover_image_path && course.cover_image_path['real']"
                 :src="course.cover_image_path['real']">

          </v-img>
        </div>

        <div class="col-3  mt-5">
          <v-switch v-model="course.is_optional" name="check-button" label="Optional">
          </v-switch>
        </div>
        <div class="col-3  mt-5">
          <v-switch v-model="course.is_visible" name="check-button" label="Visible">
          </v-switch>
        </div>
        <div class="col-3  mt-5">
          <v-switch v-model="course.is_active" name="check-button" label="Status">
          </v-switch>
        </div>

        <div class="col-12 text-right mt-4">
          <v-btn
              class="btn btn-standard text-gray cancel-btn"
              depressed
              @click="hideModal"
          >Cancel
          </v-btn>
          <v-btn v-if="checkIsAccessible('course', 'create')"
                 class="text-white ml-2 btn btn-primary"
                 depressed
                 @click="createAndUpdate"
                 :loading="isBusy"
          >Save
          </v-btn>

        </div>
      </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import CoursesService from "@/core/services/courses/CoursesService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";

const courseService = new CoursesService();

const gradeLevelService = new GradeLevelService();

export default {
  props: ["programId", 'course_id'],
  name: "course-create-and-update",
  mixins: [LessonPlannerMixin],
  validations() {
    return {
      course : this.validation_rule
    }
  }, computed: {
    validation_rule() {
      let rule = {
        title: {required},
        grade_id: {required},
        duration: {required},
        duration_unit: {required},
        theory_mark: {required},
        theory_pass_mark: {required},
      }
      if (this.course.has_practical) {
        rule.practical_pass_mark = {required}
        rule.practical_mark = {required}
      }
      return rule;
    }
  },
  data() {
    return {
      dialog: false,
      isBusy: false,
      edit: false,
      gradesLevels: [],
      course: {
        title: null,
        description: null,
        mark_type: 'Grade',
        theory_mark: null,
        theory_pass_mark: null,
        has_practical: null,
        practical_mark: null,
        practical_pass_mark: null,
        subject_code: null,
        program_id: this.programId,
        grade_id: null,
        duration: null,
        duration_unit: 'Days',
        is_optional: false,
        is_visible: true,
        is_active: true
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
    };
  },
  mounted() {
    this.getPrograms();
  },
  methods: {
    getGrades() {
      gradeLevelService.getByProgram(this.programId).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getCourse(id) {
      courseService.show(id).then(response => {
        this.edit = true;
        this.course = response.data.courses;
        this.course.has_practical = this.course.has_practical ? true : false;
        this.course.is_optional = this.course.is_optional ? true : false;
        this.course.is_active = this.course.is_active ? true : false;
        this.course.is_visible = this.course.is_visible ? true : false;
      });
    },
    showModal(id = null) {
      if (id) {
        this.getCourse(id);
        this.getAllGrades();
      }
      this.getGrades();
      this.dialog=true;
    },
    hideModal() {
      this.teacher = [];
      this.dialog=false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.course.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.updateCourse(fd);
        } else {
          this.createCourse(fd);
        }
      }
    },
    createCourse(fd) {
      this.isBusy =true;
      courseService.store(fd).then(response => {
        this.isBusy =false;
        this.resetFaculty()
        this.$snotify.success("Information added");
        this.$emit("close_dialog");
        this.$emit("refresh");
      });
    },
    updateCourse(fd) {
      this.isBusy =true;
      courseService.update(this.course.id, fd).then(response => {
        this.resetFaculty()
        this.isBusy =false;
        this.$snotify.success("Information updated");
        this.$emit("close_dialog");
        this.$emit("refresh");
      });
    }, resetFaculty() {
      this.course = {
        title: null,
        program_id: this.programId,
        grade_id: null,
        duration: null,
        duration_unit: null,
        is_optional: true,
        is_visible: true,
        is_active: true
      }
      this.$v.$reset()
    },

    generateFd() {
      delete this.course.program;
      delete this.course.grade;
      let fd = new FormData();
      for (let key in this.course) {
        if (
            key == "cover_image" &&
            this.course["cover_image"] &&
            this.course["cover_image"] != null
        ) {
          fd.append("cover_image", this.course[key]);
        } else if (key == "is_active") {
          fd.append("is_active", this.course.is_active ? 1 : 0);
        } else if (key == "is_optional") {
          fd.append("is_optional", this.course.is_optional ? 1 : 0);
        } else if (key == "has_practical") {
          fd.append("has_practical", this.course.has_practical ? 1 : 0);
        } else if (key == "is_visible") {
          fd.append("is_visible", this.course.is_visible ? 1 : 0);
        } else {
          fd.append(key, this.course[key]);
        }
      }
      return fd;
    },
  }
};
</script>
